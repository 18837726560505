import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ApiService } from '~common/api.service';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ReceiptFormComponent {
  errorMessage: string;
  cities = [];
  loading = false;
  today = new Date();

  date: string;
  code: string;
  city: string;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.api.get('receipt/cities')
      .subscribe((res) => {
        this.cities = res.meta_data.cities;
      });

    this.route.queryParams.subscribe((params) => {
      if (params.error) {
        this.errorMessage = params.error;
      }
      this.router.navigate([], {
        queryParams: {
          error: null,
        },
        queryParamsHandling: 'merge'
      })
    });
  }

  onSubmit(form: NgForm) {
    this.errorMessage = null;
    const { code, city, date } = form.value;
    if (!code) {
      this.errorMessage = 'Enter your receipt code';
      return;
    }
    if (!city) {
      this.errorMessage = 'Select the trip city';
      return;
    }
    if (!date) {
      this.errorMessage = 'Enter the trip date';
      return;
    }
    this.router.navigate(['/receipt/view'], { queryParams: { code, city, date }});
  }
}
